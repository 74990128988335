<template>
  <div class="success-page">
    <!-- <SidebarBulk /> -->
    <v-container>
      <div class="d-flex flex-column align-center">
        <div style="background-color:white;width:100%;margin-top: 50pt; padding: 30pt 5pt 30pt 5pt" class="justify-space-between">
          
          <div class="d-flex flex-column align-center justify-center">
            <div class="d-flex flex-row justify-space-between">
              <div class="label-order-date" >{{ $t("message.title-order-date") }} </div>
              <div class="value-order-date" style="padding-left: 5px">
                {{ orderPaid.created_at }}
                <!-- 2022-01-06 16:33:33 -->
              </div>
            </div>

            <div class="d-flex flex-row justify-space-between">
              <div class="label-order">{{ $t("message.title-order-code") }} </div>
              <div class="value-order">
                {{ orderPaid.order_no }}
                <!-- SO/20220106/XXII/I/0000272 -->
              </div>
            </div>
          </div>

          <div class="d-flex flex-column align-center justify-center" style="margin-top: 10pt">
            <div>{{ $t("message.title-thank-you-order") }}</div>
            <div>noreply@tajimingo.com</div>
            <div style="font-size:10pt">{{ $t("message.title-desc-copyright") }}</div>
          </div>
          <div class="d-flex flex-column align-center" style="margin-top: 20pt">
            <v-btn @click.prevent="$router.push('/bulk')" width="200px" style="margin-bottom:2%; border-color:#ff0090; background-color:#ff0090; color:white" outlined>{{ $t("message.btn-back") }}</v-btn>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
// import SidebarBulk from "../../../components/skeleton/SkeletonDetail.vue";
import SkeletonDetail from "../../components/skeleton/SkeletonDetail.vue"
export default {
  name: "SuccessPayment",
  components: {
    // SidebarBulk,
    SkeletonDetail
  },
  created() {
    this.isSuccess();
  },
  methods: {
    isSuccess() {
      let params = this.$router.history.current.name
      let payload_invoice = localStorage.getItem("invoice");
      if (params === "SuccessPayment" && payload_invoice) {
        this.$store.dispatch("order_module/fetchOrderPaid", {'invoice_no': payload_invoice});
      }
    },
  },
  computed: {
    orderPaid() {
      return this.$store.state.order_module.orderPaid
    }
  }
};
</script>

<style scoped>
/* @media (max-width: 360px) {
  .label-order-date {
  font-size: 12px;
  }
  .value-order-date {
    font-size: 10px;
    margin-left: 10px
  }
  .label-order {
    font-size: 12px;
  }
  .value-order {
    font-size: 10px;
    margin-left: 10px;
  }
} */

/* .class-container {
  display: grid;
  grid-template-rows: repeat(1,2fr);
}
.class-order-date {
  grid-row: 1;
} */
.label-order-date {
  font-size: 14px;
  
}
.value-order-date {
  font-size: 12px;
  margin-left: 10px;
}
.label-order {
  font-size: 14px;
  margin-right: 10px;
}
.value-order {
  font-size: 12px;
}
.parent-class {
  height: 91vh !important;
}
.button-class {
  position: fixed;
  margin-bottom: 0px !important;
  height: 55px !important;
  bottom: 0px;
  color: white !important;
  border-radius: 0px !important;
  box-shadow: 0px !important;
}
.header-title {
  font-family: roboto;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  height: 25px;
}
.success-page {
  background-color: #ff0090;
  height: 100vh;
  max-width: 100vw !important;
}
</style>
