<template>
  <v-skeleton-loader
    v-bind="attrs"
    type="article, image, article, list-item-two-line"
  ></v-skeleton-loader>
</template>

<script>
export default {
  data: () => ({
    attrs: {
      class: "mb-1",
      boilerplate: false,
      elevation: 0,
    },
  }),
};
</script>

<style></style>
